import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "../styles/global.css"
const Navbar = () => (
  <div className="bg-prim-dark">
    <div className="w-11/12 sm:w-7/8 lg:w-3/4 xl:w-1/2 flex flex-row justify-between mx-auto max-w-5xl">
      <h2 className="hidden ml-auto w-16 sm:block m-4">
        <Link to="/">
          <StaticImage alt="logo" src="../images/assets/vale-logo-w.svg" />
        </Link>
      </h2>
      <nav className="text-gray-200 flex flex-row items-center py-3 mx-auto">
        <div className="flex flex-row justify-between flex-wrap text-center text-md md:text-lg font-medium font-courier">
          <Link to="/" className="hover:underline px-2 mx-2">
            Home
          </Link>
          <Link to="/gallery" className="hover:underline px-2 mx-2">
            Galerie
          </Link>
          {/* <Link to="/" className="hover:underline px-2 mx-2">
        Musik
      </Link> */}
          <Link to="/about" className="hover:underline px-2 mx-2">
            Über mich
          </Link>
          <Link to="/recommendations" className="hover:underline px-2 mx-2">
            Empfehlungen
          </Link>
          <Link to="/contact" className="hover:underline px-2 mx-2">
            Kontakt
          </Link>
          <Link to="/impressum" className="hover:underline px-2 mx-2">
            Impressum
          </Link>
        </div>
      </nav>
    </div>
  </div>
)
export default Navbar
