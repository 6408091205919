import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundSlider from "gatsby-image-background-slider"
import "../styles/global.css"

const BgImg = () => (
  <BackgroundSlider
    query={useStaticQuery(graphql`
      query {
        backgrounds: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          nodes {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `)}
    initDelay={2}
    transition={4}
    duration={8}
    images={["banner/1.jpg", "banner/2.jpg", "banner/3.jpg", "banner/4.jpg"]}
    style={
      {
        // transform: "rotate(-2deg) scale(.9)",
      }
    }
  ></BackgroundSlider>
)

export default BgImg
