import * as React from "react"
import { Link } from "gatsby"
import Navbar from "./ezNavbar"
import BgImg from "./bgImage"

const Header = ({ siteTitle, subTitle }) => (
  <header className="w-full">
    <div className="h-96 z-0 relative">
      <BgImg />
    </div>
    <div className="h-48 -mt-72 absolute w-full font-courier ">
      <div className="text-center p-2 sm:p-8 md:p-12 mix-blend-difference text-gray-200 select-none">
        <h1 className="text-6xl">
          <Link to="/">{siteTitle}</Link>
        </h1>
        <h2 className="text-2xl">{subTitle}</h2>
      </div>
    </div>
    <Navbar />
  </header>
)

export default Header
